"use strict";
import { dcFactory } from "@deleteagency/dc";
class App {
  constructor() {
    this.isMobile = document.documentElement.classList.contains("is-mobile");
  }
  init() {
    dcFactory.init();
  }
}
const instance = new App();
export default instance;
